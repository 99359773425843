import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import {
  Card,
  Input,
  message,
  notification,
  Select,
  Table,
  Button as IconButton,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ERoles, IPond } from "pages/Ponds/types";
import { useMsal } from "@azure/msal-react";
import { addRepos, loadedRepos } from "http/userApi";
import { generateRoles, modalError } from "helpers";
import { repoColumns } from "const";
import { Roles as RolesType, RolesToDisplay } from "./types";
import { v4 as uuidv4 } from "uuid";

import Button from "common/Button";
import Tooltip from "common/Tooltip";

import { AccessLevelTooltip } from "../Tooltips/AccessLevelTooltip";
import { FolderTooltip } from "../Tooltips/FolderTooltip";
import { GitHubRepositoriesTooltip } from "../Tooltips/GitHubRepositoriesTooltip";
import { RepositoryNameTooltip } from "../Tooltips/RepositoryNameTooltip";

import "./style.scss";

const GitHub = ({ bucket }: { bucket: IPond }) => {
  const msalInstance = useMsal();
  const [edit, setEdit] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [savedRepos, setSavedRepos] = useState<{
    [index: string]: {
      level: keyof typeof ERoles;
      prefix: string;
      created_by: string;
    }[];
  }>();

  const [reposCollection, setReposCollection] = useState<RolesToDisplay[]>([]);

  useEffect(() => {
    const getRepos = async () => {
      setLoading(true);

      const result: {
        data: { data: RolesType } | null;
        error: null | string;
      } = await loadedRepos(bucket.name, msalInstance);

      if (result.data && !result.error) {
        setSavedRepos(result.data.data);
        setReposCollection(generateRoles(result.data.data));
      }

      setLoading(false);
    };

    getRepos();
  }, [bucket.name]);

  const switchToEdit = useCallback((block: string) => setEdit(block), []);

  const editCancel = useCallback(() => {
    if (savedRepos) {
      setReposCollection(generateRoles(savedRepos));
    }
    setEdit("");
  }, [savedRepos]);

  const handleAddSpecifyFolders = (itemKey: number) => {
    setReposCollection(
      reposCollection.map(reposItem =>
        reposItem.key === itemKey
          ? {
              ...reposItem,
              type: {
                ...reposItem.type,
                value: [
                  ...reposItem.type.value,
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
              },
            }
          : reposItem,
      ),
    );
  };

  const handleAddRepos = useCallback(
    () =>
      reposCollection.length
        ? setReposCollection([
            ...reposCollection,
            {
              key: reposCollection[reposCollection.length - 1].key + 1,
              name: {
                name: "",
                itemKey:
                  reposCollection[reposCollection.length - 1].name.itemKey + 1,
              },
              select: {
                value: "all",
                itemKey:
                  reposCollection[reposCollection.length - 1].name.itemKey + 1,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey:
                  reposCollection[reposCollection.length - 1].type.itemKey + 1,
              },
            },
          ])
        : setReposCollection([
            {
              key: 0,
              name: {
                name: "",
                itemKey: 0,
              },
              select: {
                value: "all",
                itemKey: 0,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey: 0,
              },
            },
          ]),
    [reposCollection],
  );

  const savePermissions = async () => {
    setLoading(true);

    for (let i = 0; i < reposCollection.length; i++) {
      if (reposCollection[i].name.name === "") {
        setLoading(false);
        return notification.error({
          message:
            'The "Repository name" field is required. Make sure they are filled in.',
        });
      }

      if (
        reposCollection[i].select.value === "specify" &&
        reposCollection[i].type.value.length === 1 &&
        reposCollection[i].type.value[0].prefix === ""
      ) {
        setLoading(false);
        return notification.error({
          message: 'The "Specify folders" field must not be empty',
        });
      }

      if (
        reposCollection[i] &&
        reposCollection[i + 1] &&
        reposCollection[i].name.name === reposCollection[i + 1].name.name
      ) {
        setLoading(false);
        return notification.error({
          message: "The repos's name must be unique",
        });
      }
    }

    const repos: {
      [index: string]: {
        prefix: string;
        level: keyof typeof ERoles;
        created_by: string;
      }[];
    } = {};

    reposCollection.forEach(item => {
      Object.assign(repos, {
        [item.name.name]: item.type.value.map(
          ({ prefixKey, created_by, ...rest }) => rest,
        ),
      });
    });

    const result = await addRepos(repos, bucket.name, msalInstance);

    if (result.data) {
      setReposCollection(generateRoles(result.data));
      message.success({
        content: "Repo was created successfully",
        duration: 2,
      });
    }

    if (result.error) {
      modalError(result.error);
    } else {
      setEdit("");
    }

    setLoading(false);
  };

  const handleInputValue = (
    inputValue: string,
    field: string,
    item: { name: string; itemKey: number },
  ) => {
    setReposCollection(
      reposCollection.map(reposItem =>
        reposItem.key === item.itemKey
          ? {
              ...reposItem,
              [field]: { itemKey: item.itemKey, name: inputValue },
            }
          : reposItem,
      ),
    );
  };

  const handleChangePrefixInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: number) => {
      setReposCollection(
        reposCollection.map(reposItem =>
          reposItem.key === key
            ? {
                ...reposItem,
                type: {
                  ...reposItem.type,
                  value: reposItem.type.value.map(item =>
                    item.prefixKey === event.target.name
                      ? {
                          ...item,
                          prefix: event.target.value,
                        }
                      : item,
                  ),
                },
              }
            : reposItem,
        ),
      );
    },
    [reposCollection],
  );

  const handleChangeFoldersSelect = useCallback(
    (value: string, itemKey: number) => {
      setReposCollection(
        reposCollection.map(reposItem =>
          reposItem.key === itemKey
            ? {
                ...reposItem,
                select: { value: value, itemKey },
                type: {
                  ...reposItem.type,
                  value:
                    value === "all"
                      ? [
                          {
                            level: "ro",
                            prefix: "",
                            prefixKey: uuidv4(),
                          },
                        ]
                      : reposItem.type.value,
                },
              }
            : reposItem,
        ),
      );
    },
    [reposCollection],
  );

  const handleDelete = useCallback(
    (
      repoKey: number,
      prefixKey: string,
      found: {
        level: "ro" | "owner" | "rw";
        prefix: string;
        prefixKey?: string | undefined;
      }[],
    ) => {
      if (found.length === 1) {
        const owners = reposCollection.filter(item =>
          item.type.value.find(elem => elem.level === "owner"),
        );

        if (owners.length === 1 && owners[0].key === repoKey) {
          notification.error({
            message: "Error",
            description: "Can't delete the last owner",
          });
          return;
        }

        setReposCollection(
          reposCollection.filter(reposItem => reposItem.key !== repoKey),
        );

        return;
      }

      setReposCollection(
        reposCollection.map(reposItem =>
          reposItem.key === repoKey
            ? {
                ...reposItem,
                type: {
                  ...reposItem.type,
                  value: reposItem.type.value.filter(
                    item => item.prefixKey !== prefixKey,
                  ),
                },
              }
            : reposItem,
        ),
      );
    },
    [reposCollection],
  );

  const handleChangePrefixSelect = useCallback(
    (key: number, value: keyof typeof ERoles, prefixKey: string) => {
      setReposCollection(
        reposCollection.map(reposItem =>
          reposItem.key === key
            ? {
                ...reposItem,
                type: {
                  ...reposItem.type,
                  value: reposItem.type.value.map(item =>
                    item.prefixKey === prefixKey
                      ? {
                          ...item,
                          level: value,
                        }
                      : item,
                  ),
                },
              }
            : reposItem,
        ),
      );
    },
    [reposCollection],
  );

  const editableRepoColumns = [
    {
      title: () => (
        <Tooltip title="Repository name" tooltip={<RepositoryNameTooltip />} />
      ),
      dataIndex: "name",
      render: (record: { name: string; itemKey: number }) => {
        return (
          <Input
            defaultValue={record.name}
            onChange={e => handleInputValue(e.target.value, "name", record)}
          />
        );
      },
      key: "name",
      width: "20%",
    },
    {
      title: () => (
        <Tooltip title="Folders access mode" tooltip={<FolderTooltip />} />
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        return (
          <Select
            className="column-folders-select"
            value={record.value}
            onChange={(value: string) =>
              handleChangeFoldersSelect(value, record.itemKey)
            }
          >
            <Select.Option value="all">All folders</Select.Option>
            <Select.Option value="specify">Specify folders</Select.Option>
          </Select>
        );
      },
      key: "select",
      width: "10%",
    },
    {
      title: () => (
        <div className="access-titles">
          <div>Pond folders</div>
          <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
          <div>Action</div>
        </div>
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        const foundItem = reposCollection.find(
          item => item.key === record.itemKey,
        );

        return (
          <>
            {record.value === "all" ? (
              <div className="access-fields-wrapper">
                <Input
                  name="all"
                  onChange={e => handleChangePrefixInput(e, record.itemKey)}
                  value={foundItem?.type.value[0].prefix}
                  disabled={record.value === "all"}
                />
                <Select
                  className="item-selector"
                  onChange={value =>
                    handleChangePrefixSelect(
                      record.itemKey,
                      value,
                      foundItem?.type.value[0].prefixKey
                        ? foundItem.type.value[0].prefixKey
                        : "",
                    )
                  }
                  value={foundItem?.type.value[0].level}
                >
                  <Select.Option value="owner">Owner</Select.Option>
                  <Select.Option value="ro">Read only</Select.Option>
                  <Select.Option value="rw">Read write</Select.Option>
                </Select>
                <div style={{ maxWidth: "fit-content" }}>
                  <IconButton
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      handleDelete(
                        record.itemKey,
                        foundItem?.type.value[0].prefixKey
                          ? foundItem.type.value[0].prefixKey
                          : "",
                        foundItem?.type.value ? foundItem?.type.value : [],
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              foundItem?.type.value.map(item => (
                <div className="access-fields-wrapper">
                  <Input
                    name={item.prefixKey}
                    onChange={e => handleChangePrefixInput(e, record.itemKey)}
                    value={item.prefix}
                    disabled={record.value === "all"}
                  />
                  <Select
                    className="item-selector"
                    onChange={value =>
                      handleChangePrefixSelect(
                        record.itemKey,
                        value,
                        item.prefixKey ? item.prefixKey : "",
                      )
                    }
                    value={item.level}
                  >
                    <Select.Option value="owner">Owner</Select.Option>
                    <Select.Option value="ro">Read only</Select.Option>
                    <Select.Option value="rw">Read write</Select.Option>
                  </Select>
                  <div style={{ maxWidth: "fit-content" }}>
                    <IconButton
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        handleDelete(
                          record.itemKey,
                          item.prefixKey ? item.prefixKey : "",
                          foundItem.type.value,
                        )
                      }
                    />
                  </div>
                </div>
              ))
            )}
            {record.value === "all" ? null : (
              <Button
                style={{
                  width: "100%",
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size="middle"
                type="default"
                onClick={() => handleAddSpecifyFolders(record.itemKey)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            )}
          </>
        );
      },
      key: "pondFolders",
      width: "70%",
    },
  ];

  return (
    <div className="access-pond">
      <Card
        title={
          <Tooltip
            title="GitHub repositories"
            tooltip={<GitHubRepositoriesTooltip />}
          />
        }
        className="access-pond-roles"
        extra={
          edit !== "roles" ? (
            <Button
              type="primary"
              size="large"
              onClick={() => switchToEdit("roles")}
              icon={<EditOutlined />}
              disabled={
                bucket.management_permissions.can_add_github_repositories
                  ? false
                  : true
              }
            >
              Edit
            </Button>
          ) : (
            <div className="edit-buttons">
              <Button className="cancel-btn" onClick={editCancel} size="large">
                Cancel
              </Button>
              <Button
                htmlType="submit"
                disabled={loading ? true : false}
                type="primary"
                size="large"
                onClick={savePermissions}
              >
                Save
              </Button>
            </div>
          )
        }
      >
        {edit === "roles" ? (
          <>
            <Table
              dataSource={reposCollection}
              columns={editableRepoColumns}
              loading={loading}
              pagination={false}
            />
            <Button
              type="dashed"
              disabled={loading}
              className="add-permissions-btn"
              icon={<PlusOutlined />}
              onClick={handleAddRepos}
            >
              Add repository
            </Button>
          </>
        ) : bucket.management_permissions.can_add_github_repositories ? (
          <Table
            columns={repoColumns}
            dataSource={reposCollection}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="disabled-block">
            <Table
              columns={repoColumns}
              dataSource={reposCollection}
              pagination={false}
              loading={loading}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default memo(GitHub);
