import { useCallback, useMemo } from "react";
import { IMsalContext } from "@azure/msal-react";
import { Modal } from "antd";
import { loginRequest } from "config/auth";

export const useLogin = (msalInstance: IMsalContext) => {
  const login = useCallback(
    ({ reloadPage = true } = {}) => {
      msalInstance.instance
        .loginPopup(loginRequest)
        .then(() => (reloadPage ? location.reload() : {}))
        .catch(e => {
          console.log(e);
        });
    },
    [msalInstance.instance],
  );

  const logout = useCallback(() => {
    msalInstance.instance.logoutPopup().catch(e => {
      console.log(e);
    });
  }, [msalInstance.instance]);

  const showAuthorizationErrorModal = useCallback(
    () =>
      Modal.error({
        title: "Authorization",
        content: "Your session has expired. Please, log in again to continue",
        okText: "Login",
        onOk: login,
      }),
    [login],
  );

  return useMemo(
    () => ({ login, logout, showAuthorizationErrorModal }),
    [login, logout, showAuthorizationErrorModal],
  );
};
