import { memo, useCallback, useMemo, useState } from "react";
import { Card, Descriptions, message, Row, Tooltip } from "antd";
import { useMsal } from "@azure/msal-react";
import { AxiosResponse } from "axios";
import { getCredentialsRequest, getJdbcString } from "http/userApi";
import { CopyOutlined, RedoOutlined } from "@ant-design/icons";

import { Credentials } from "components/Users/components/AccessData/types";
import { IPond } from "pages/Ponds/types";

import { formatDateTime, handleCopyText } from "helpers";

import Button from "common/Button";
import Spinner from "common/Spinner";
import { useLogin } from "hooks/useLogin";

import { UpdateCredentialsTooltip } from "./components/UpdateCredentialsTooltip";

import "./style.scss";

const CredentialsTab = ({ bucket }: { bucket: IPond }) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [credentials, setCredentials] = useState<Credentials>();
  const [jdbcString, setJdbcString] = useState("");
  const [loading, setLoading] = useState<string>("");

  const labelStyle = useMemo(
    () => ({
      width: 200,
      color: "#8c8c8c",
    }),
    [],
  );

  const handleGetCredentials = useCallback(async () => {
    setLoading("get");
    const result: {
      data: AxiosResponse<{ data: { session: Credentials } }> | null;
      error: null | string;
    } = await getCredentialsRequest(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data?.data) {
      setCredentials(result.data.data.data.session);
    }

    if (result.error) {
      message.error({
        content: "Something wrong",
        duration: 2,
      });
    }

    setJdbcString("");
    setLoading("");
  }, [bucket.name, msalInstance, showAuthorizationErrorModal]);

  const getJdbcConnectionString = useCallback(async () => {
    setLoading("jdbc");

    const result: {
      data: string | null;
      error: null | string;
    } = await getJdbcString(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data) {
      setJdbcString(result.data);
    }

    if (result.error) {
      message.error({
        content: "Something wrong",
        duration: 2,
      });
    }

    setLoading("");
  }, [bucket.name, msalInstance, showAuthorizationErrorModal]);

  return (
    <>
      {!credentials ? (
        <Card
          className="get-credentials"
          title="Temporary access"
          extra={
            <Button onClick={handleGetCredentials} type="primary" size="large">
              Get credentials
            </Button>
          }
        />
      ) : null}
      {loading === "get" ? (
        <Spinner />
      ) : credentials ? (
        <>
          <Card
            className="credentials-card"
            title="Temporary access"
            extra={
              <div className="credentials-card-buttons">
                <Tooltip title={<UpdateCredentialsTooltip />}>
                  <Button
                    size="large"
                    icon={<RedoOutlined />}
                    onClick={handleGetCredentials}
                  >
                    Update credentials
                  </Button>
                </Tooltip>
              </div>
            }
          >
            <Row>
              <Descriptions column={2}>
                <Descriptions.Item
                  label="Access Key ID"
                  labelStyle={labelStyle}
                  className="descr-item"
                >
                  <div>
                    {credentials?.AccessKeyId}
                    <CopyOutlined
                      className="copy-icon"
                      onClick={handleCopyText(
                        "Access Key ID",
                        credentials?.AccessKeyId,
                      )}
                    />
                  </div>
                </Descriptions.Item>
                <Descriptions.Item
                  label="Valid until"
                  labelStyle={labelStyle}
                  className="descr-item"
                >
                  {formatDateTime(Number(credentials?.ValidUntil))}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={1}>
                <Descriptions.Item
                  label="Secret access key"
                  labelStyle={labelStyle}
                  className="descr-item"
                >
                  ************************************************************************************************************
                  <CopyOutlined
                    className="copy-icon"
                    onClick={handleCopyText(
                      "Secret access key",
                      credentials?.SecretAccessKey,
                    )}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={1}>
                <Descriptions.Item
                  label="JDBC connection string"
                  labelStyle={labelStyle}
                  className="descr-item"
                >
                  {bucket.params.athena.enable ? (
                    jdbcString ? (
                      <>
                        ************************************************************************************************************
                        <CopyOutlined
                          className="copy-icon"
                          onClick={handleCopyText(
                            "JDBC connection string",
                            jdbcString,
                          )}
                        />
                      </>
                    ) : (
                      <Button
                        loading={loading === "jdbc"}
                        type="primary"
                        onClick={getJdbcConnectionString}
                      >
                        Get string
                      </Button>
                    )
                  ) : (
                    "Athena is disabled"
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Row>
          </Card>
        </>
      ) : null}
    </>
  );
};

export default memo(CredentialsTab);
