import { IMsalContext } from "@azure/msal-react";
import { isAxiosError, AxiosError, AxiosResponse } from "axios";

import { IAuditLog } from "../components/Logs/AuditLog/types";
import { IAthenaObject } from "../components/Connect/components/Athena/types";
import { getAccessToken } from "../config/token";
import { convertRoleToRoles, getHeaders, handleRequestError } from "../helpers";
import { ERoles, IPond } from "../pages/Ponds/types";
import { $bucketUrlHost } from "./index";
import {
  Credentials,
  MachineUsers,
  MachineUsersSchema,
  SSHKey,
  SSHKeySchema,
} from "../components/Users/components/AccessData/types";
import { Role, Roles } from "../components/Users/components/Roles/types";
import { StaticSiteConfig } from "../components/StaticSite/types";
import { Group } from "components/Users/components/AccessGroups/types";
import { IAccessLog } from "components/Logs/AccessLog/types";
import { Applications } from "components/Users/components/AzureApplication/types";

type Repos = Roles;

export const addBucket = async (
  bucket: IPond,
  msalInstance: IMsalContext,
): Promise<{
  data: AxiosResponse<IPond> | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: AxiosResponse<IPond> = await $bucketUrlHost.post(
      "v2/s3buckets",
      bucket,
      { headers: getHeaders(token?.token) },
    );
    return {
      error: null,
      data,
    };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 422) {
        const errorTyped = error.response.data as {
          detail: Array<{
            msg: string;
            type: string;
          }>;
        };

        if (errorTyped.detail[0].type === "value_error.str.regex") {
          return {
            error:
              "The pond name contains invalid characters (upper case letters cannot be used).",
            data: null,
          };
        }

        return {
          error: errorTyped.detail[0].msg,
          data: null,
        };
      } else if (error.response?.status === 500) {
        return {
          error: "Something went wrong...",
          data: null,
        };
      } else {
        return {
          error: (error.response?.data as { detail: string }).detail,
          data: null,
        };
      }
    } else {
      return {
        data: null,
        error: (error as { message: string }).message,
      };
    }
  }
};

export const noopSwitch = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  return new Promise(resolve => {
    return resolve({
      error: null,
      data: `${msalInstance ? id : "noop"}`,
    });
  });
};

export const enableAthena = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.post(
      `v2/s3buckets/${id}/athena`,
      {},
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data: "Athena has been changed",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const disableAthena = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.delete(`v2/s3buckets/${id}/athena`, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: "Athena has been changed",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const enableArchive = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.post(
      `v2/s3buckets/${id}/archive`,
      {},
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data: "Archive has been changed",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const disableArchive = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.delete(`v2/s3buckets/${id}/archive`, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: "Archive has been changed",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const changeBucket = async (
  name: string,
  bucket: Partial<IPond>,
  msalInstance: IMsalContext,
): Promise<{
  data: IPond | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data }: AxiosResponse<IPond> = await $bucketUrlHost.patch(
      `v2/s3buckets/${name}`,
      bucket,
      { headers: getHeaders(token?.token) },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const addMachineUser = async (
  body: MachineUsersSchema,
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: AxiosResponse<MachineUsers> | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: AxiosResponse<MachineUsers> = await $bucketUrlHost.post(
      `v2/s3buckets/${id}/machine-users`,
      body,
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 422) {
        return {
          error: (
            error.response.data as {
              detail: Array<{
                msg: string;
              }>;
            }
          ).detail[0].msg,
          data: null,
        };
      } else {
        return {
          error: (error.response?.data as { detail: string }).detail,
          data: null,
        };
      }
    } else {
      return {
        data: null,
        error: (error as { message: string }).message,
      };
    }
  }
};

export const removeMachineUser = async (
  id: string,
  machineUserId: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.delete(
      `v2/s3buckets/${id}/machine-users/${machineUserId}`,
      { headers: getHeaders(token?.token) },
    );

    return {
      error: null,
      data: "Static credential was deleted successfully",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const addSSHKey = async (
  pond_name: string,
  user_id: string,
  body: SSHKeySchema,
  msalInstance: IMsalContext,
): Promise<{
  data: SSHKey | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data }: AxiosResponse<SSHKey> = await $bucketUrlHost.post(
      `v2/s3buckets/${pond_name}/machine-users/${user_id}/ssh-keys`,
      body,
      { headers: getHeaders(token?.token) },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const deleteSSHKey = async (
  pond_name: string,
  user_id: string,
  ssh_key_id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data }: AxiosResponse<{ msg: string }> =
      await $bucketUrlHost.delete(
        `v2/s3buckets/${pond_name}/machine-users/${user_id}/ssh-keys/${ssh_key_id}`,
        { headers: getHeaders(token?.token) },
      );

    return {
      error: null,
      data: data.msg,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const loadedRoles = async (id: string, msalInstance: IMsalContext) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: { data: Role[] } = await $bucketUrlHost.get(
      `v2/s3buckets/${id}/principals`,
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data: convertRoleToRoles(data.data),
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const loadedRepos = async (id: string, msalInstance: IMsalContext) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: { data: Repos } = await $bucketUrlHost.get(
      `v2/s3buckets/${id}/github-repositories`,
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const loadedUsers = async (id: string, msalInstance: IMsalContext) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data } = await $bucketUrlHost.get(`v2/s3buckets/${id}/users`, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const loadedGroups = async (id: string, msalInstance: IMsalContext) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data } = await $bucketUrlHost.get(`v2/s3buckets/${id}/groups`, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const loadedApplications = async (
  id: string,
  msalInstance: IMsalContext,
) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data }: { data: Applications } = await $bucketUrlHost.get(
      `v2/s3buckets/${id}/applications`,
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const addRole = async (
  body: {
    [index: string]: {
      prefix: string;
      level: keyof typeof ERoles;
      created_by: string;
    }[];
  },
  id: string,
  msalInstance: IMsalContext,
) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const {
      data,
    }: {
      data: Role[];
    } = await $bucketUrlHost.put(`v2/s3buckets/${id}/principals`, body, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: convertRoleToRoles(data),
    };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 422) {
        return {
          error: (
            error.response.data as {
              detail: Array<{
                msg: string;
              }>;
            }
          ).detail[0].msg,
          data: null,
        };
      } else if (error.response?.status === 400) {
        return {
          error: error.response.data as {
            detail: string;
          },
          data: null,
        };
      } else {
        return {
          error: (error.response?.data as { detail: string }).detail,
          data: null,
        };
      }
    } else {
      return {
        data: null,
        error: (error as { message: string }).message,
      };
    }
  }
};

export const addRepos = async (
  body: {
    [index: string]: {
      prefix: string;
      level: keyof typeof ERoles;
      created_by: string;
    }[];
  },
  id: string,
  msalInstance: IMsalContext,
) => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const {
      data,
    }: {
      data: Repos;
    } = await $bucketUrlHost.put(
      `v2/s3buckets/${id}/github-repositories`,
      body,
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 422) {
        return {
          error: (
            error.response.data as {
              detail: Array<{
                msg: string;
              }>;
            }
          ).detail
            .map(el => el.msg)
            .join("\n"),
          data: null,
        };
      } else {
        return {
          error: (error.response?.data as { detail: string }).detail,
          data: null,
        };
      }
    } else {
      return {
        data: null,
        error: (error as { message: string }).message,
      };
    }
  }
};

export const getAuditLog = async (
  bucketId: string,
  days: string,
  msalInstance: IMsalContext,
): Promise<{
  data: AxiosResponse<{ Logs: IAuditLog[] }> | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: AxiosResponse<{ Logs: IAuditLog[] }> = await $bucketUrlHost.get(
      `v2/s3buckets/${bucketId}/audit?days=${days}`,
      { headers: getHeaders(token?.token) },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const getAccessLog = async (
  bucketId: string,
  dateFrom: string,
  dateTo: string,
  msalInstance: IMsalContext,
): Promise<{
  data: IAccessLog | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data }: { data: IAccessLog } = await $bucketUrlHost.get(
      `v2/s3buckets/${bucketId}/access-logs?date_from=${dateFrom}&date_to=${dateTo}`,
      { headers: getHeaders(token?.token) },
    );

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const getCredentialsRequest = async (
  bucketId: string,
  msalInstance: IMsalContext,
): Promise<{
  data: AxiosResponse<{ data: { session: Credentials } }> | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: AxiosResponse<{ data: { session: Credentials } }> =
      await $bucketUrlHost.get(`v2/s3buckets/${bucketId}/keys?no_cache=true`, {
        headers: getHeaders(token?.token),
      });

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const deletePondRequest = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.delete(`v2/s3buckets/${id}`, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: "Pond was deleted successfully",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const toggleCrawler = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.post(
      `v2/s3buckets/${id}/athena/run-crawler`,
      {},
      { headers: getHeaders(token?.token) },
    );

    return {
      data: "Crawler was triggered",
      error: null,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const getJdbcString = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const {
      data,
    }: {
      data: {
        data: {
          session: {
            ConnectionString: string;
          };
        };
      };
    } = await $bucketUrlHost.get(`v2/s3buckets/${id}/athena/jdbc`, {
      headers: getHeaders(token?.token),
    });

    return {
      data: data.data.session.ConnectionString,
      error: null,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const getAthenaObject = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: IAthenaObject | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const data: {
      data: IAthenaObject;
    } = await $bucketUrlHost.get(`v2/s3buckets/${id}/athena`, {
      headers: getHeaders(token?.token),
    });

    return {
      data: data.data,
      error: null,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const activeStaticSite = async (
  name: string,
  msalInstance: IMsalContext,
): Promise<{
  data: StaticSiteConfig | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const { data }: { data: StaticSiteConfig } = await $bucketUrlHost.post(
      `v2/s3buckets/${name}/website`,
      {},
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data: data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const disableStaticSite = async (
  name: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.delete(`v2/s3buckets/${name}/website`, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: "Static website was disabled",
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const changeStaticAuth = async (
  name: string,
  checked: boolean,
  msalInstance: IMsalContext,
): Promise<{
  data: StaticSiteConfig | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const url = `v2/s3buckets/${name}/website/authorization`;

    const {
      data,
    }: { data: { static_website_hosting_config: StaticSiteConfig } } = checked
      ? await $bucketUrlHost.post(
          url,
          {},
          {
            headers: getHeaders(token?.token),
          },
        )
      : await $bucketUrlHost.delete(url, {
          headers: getHeaders(token?.token),
        });

    return {
      error: null,
      data: data.static_website_hosting_config,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const switchCollibra = async (
  id: string,
  msalInstance: IMsalContext,
): Promise<{
  data: string | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    await $bucketUrlHost.post(
      `v2/s3buckets/${id}/collibra`,
      {},
      {
        headers: getHeaders(token?.token),
      },
    );

    return {
      error: null,
      data: "Collibra successfully activated",
    };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 406) {
        const errorTyped = error.response.data as {
          message: string;
        };

        return {
          error: errorTyped.message,
          data: null,
        };
      } else {
        return {
          error: (error.response?.data as { detail: string }).detail,
          data: null,
        };
      }
    } else {
      return {
        data: null,
        error: (error as { message: string }).message,
      };
    }
  }
};

export const changeUsers = async (
  name: string,
  users: {
    [index: string]: {
      level: keyof typeof ERoles;
    };
  },
  msalInstance: IMsalContext,
): Promise<{
  data: {
    [index: string]: {
      level: keyof typeof ERoles;
      prefix: string;
    }[];
  } | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const {
      data,
    }: {
      data: {
        [index: string]: {
          level: keyof typeof ERoles;
          prefix: string;
        }[];
      };
    } = await $bucketUrlHost.put(`v2/s3buckets/${name}/users`, users, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const changeGroups = async (
  name: string,
  groups: {
    [index: string]: {
      level: keyof typeof ERoles;
    };
  },
  msalInstance: IMsalContext,
): Promise<{
  data: Group[] | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const {
      data,
    }: {
      data: Group[];
    } = await $bucketUrlHost.put(`v2/s3buckets/${name}/groups`, groups, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};

export const changeApplications = async (
  name: string,
  users: {
    [index: string]: {
      level: keyof typeof ERoles;
    };
  },
  msalInstance: IMsalContext,
): Promise<{
  data: {
    [index: string]: {
      level: keyof typeof ERoles;
      prefix: string;
    }[];
  } | null;
  error: null | string;
}> => {
  try {
    const token = await getAccessToken(msalInstance);

    if (token?.idTokenClaims) {
      const time = parseInt((new Date().getTime() / 1000).toString());

      if (token.idTokenClaims <= time) {
        return {
          data: null,
          error: "token expired",
        };
      }
    }

    const {
      data,
    }: {
      data: {
        [index: string]: {
          level: keyof typeof ERoles;
          prefix: string;
        }[];
      };
    } = await $bucketUrlHost.put(`v2/s3buckets/${name}/applications`, users, {
      headers: getHeaders(token?.token),
    });

    return {
      error: null,
      data: data,
    };
  } catch (error) {
    return handleRequestError(error as AxiosError | { message: string });
  }
};
