import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Header from "./components/Header";
import Routes from "./components/Routes";
import { isDevelopment } from "./const";
import Main from "./pages/Main";

function App() {
  return (
    <div className="app">
      <Header />
      {!isDevelopment ? (
        <>
          <AuthenticatedTemplate>
            <Routes />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Main />
          </UnauthenticatedTemplate>
        </>
      ) : (
        <Routes />
      )}
    </div>
  );
}

export default App;
