import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Link,
  Params,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Input, message, notification, PageHeader, Popover } from "antd";
import { useMsal } from "@azure/msal-react";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { initialBucket, pondBreadcrumbs } from "const";
import { EPondTabsMenu, IPond } from "../Ponds/types";
import { bucketUrl, explorerUrl, hostnameUrl } from "http/index";
import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { deletePondRequest } from "http/userApi";
import { ShareIcon } from "assets/jsx/share";

import useFetch from "hooks/useFetch";
import { useLogin } from "hooks/useLogin";

import Button from "common/Button";
import Spinner from "common/Spinner";
import TabMenu from "common/TabMenu";
import General from "components/General";
import CredentialsTab from "components/CredentialsTab";
import Users from "components/Users";
import Connect from "components/Connect";
import StaticSite from "components/StaticSite";
import { Logs } from "components/Logs";

import "./style.scss";

const Pond = () => {
  const navigate = useNavigate();
  const { id }: Params<string> = useParams();
  const [params] = useSearchParams();
  const tab = params.get("tab");
  const section = params.get("section");
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [bucket, setBucket] = useState<IPond>(initialBucket);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deletingPondName, setDeletingPondName] = useState("");

  const canDeletePond = deletingPondName === id;

  const { data, error, isLoading, token, fetchData } = useFetch<IPond>(
    `${bucketUrl}v2/s3buckets/${id}`,
    msalInstance,
  );

  console.log("Pond data", data);

  useEffect(() => {
    if (bucket.name && section) {
      document.getElementById(section)?.scrollIntoView(true);
    } else {
      window.scrollTo({ top: 0, behavior: "instant" as ScrollBehavior });
    }
  }, [tab, section, bucket.name]);

  useEffect(() => {
    if (token === "token expired") {
      showAuthorizationErrorModal();
    }

    if (error) {
      navigate("/404");
    }
  }, [error, navigate, showAuthorizationErrorModal, token]);

  useEffect(() => {
    if (error && error.message) {
      notification.error({
        message: "Something's wrong",
        description: error.message,
        onClose: () => navigate("/"),
      });
    } else if (!error && data) {
      setBucket(data);
    }
  }, [error, navigate, data]);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
    if (!newVisible) {
      setDeletingPondName("");
    }
  };

  const hidePopover = useCallback(() => {
    handleVisibleChange(false);
  }, [handleVisibleChange]);

  const itemRender = (route: Route) => {
    if (route.path === "/:id") {
      return <div>{data?.name}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const deletePond = useCallback(async () => {
    setLoading(true);
    if (id) {
      const result: {
        data: string | null;
        error: null | string;
      } = await deletePondRequest(id, msalInstance);

      if (result.error === "token expired") {
        return showAuthorizationErrorModal();
      }

      if (result.data) {
        setDeletingPondName("");
        navigate("/");
        message.success({
          content: result.data,
          duration: 2,
        });
      }

      if (result.error) {
        message.error({
          content: "Something wrong",
          duration: 2,
        });
      }
    }
    setLoading(false);
  }, [id, navigate, msalInstance, showAuthorizationErrorModal]);

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success({
      content: "Bucket link is copied",
      duration: 1,
    });
  };

  const handleChangeDeletingPondName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDeletingPondName(e.target.value.trim());
    },
    [id],
  );

  const deletePopover = (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure you want to delete this pond?</span>
      </div>
      <div className="popup-buttons">
        <Input
          onChange={handleChangeDeletingPondName}
          value={deletingPondName}
          name="pond-name"
          placeholder="To confirm enter Pond name"
          maxLength={id ? id.length + 1 : 100}
        />
        <Button type="default" size="small" onClick={hidePopover}>
          No
        </Button>
        <Button
          type="primary"
          size="small"
          loading={loading}
          onClick={deletePond}
          disabled={!canDeletePond}
        >
          Yes
        </Button>
      </div>
    </div>
  );

  const tabs = useMemo(
    () =>
      [
        {
          name: EPondTabsMenu.GENERAL,
          page: (
            <>
              <General
                bucket={bucket}
                onFetchBucket={fetchData}
                loading={loading}
                onSetLoading={setLoading}
              />
              <StaticSite
                bucket={bucket}
                onFetchBucket={fetchData}
                loading={loading}
                onSetLoading={setLoading}
              />
            </>
          ),
        },
        {
          name: EPondTabsMenu.USERS,
          page: <Users bucket={bucket} onFetchBucket={fetchData} />,
        },
        {
          name: EPondTabsMenu.CONNECT,
          page: <Connect bucket={bucket} onFetchBucket={fetchData} />,
        },
        {
          name: EPondTabsMenu.KEYS,
          page: <CredentialsTab bucket={bucket} />,
        },
        {
          name: EPondTabsMenu.LOGS,
          page: (
            <Logs
              bucketName={bucket.name}
              permissions={data?.management_permissions}
            />
          ),
          isVisible: !!data?.management_permissions.can_add_users,
        },
      ].map(({ page, ...fields }) => ({
        ...fields,
        ...(bucket?.name && { page }),
      })),
    [
      bucket,
      loading,
      setLoading,
      fetchData,
      data?.management_permissions.can_add_users,
    ],
  );

  return (
    <div className="pond-page">
      {isLoading ? (
        <Spinner className="spinner-pond" />
      ) : !error ? (
        <>
          <PageHeader
            title={
              <div className="header-title">
                {`Pond ID: ${data?.name}`}
                <a
                  className="browse-files-link"
                  target="_blank"
                  href={`${hostnameUrl}${explorerUrl}?bucket_name=${bucket.name}&bucket_region=${bucket.params.region}`}
                >
                  Browse Files
                  <ShareIcon />
                </a>
              </div>
            }
            className="breadcrumbs"
            breadcrumb={{
              routes: pondBreadcrumbs,
              itemRender,
            }}
            extra={
              <div className="header-buttons">
                {data?.management_permissions.can_add_users && (
                  <Popover
                    content={deletePopover}
                    trigger="click"
                    open={visible}
                    onOpenChange={handleVisibleChange}
                  >
                    <Button
                      className="delete-btn"
                      size="large"
                      type="link"
                      icon={<DeleteOutlined />}
                    >
                      Delete pond
                    </Button>
                  </Popover>
                )}
                <Button
                  size="large"
                  onClick={handleCopy}
                  icon={<CopyOutlined />}
                >
                  Copy link
                </Button>
              </div>
            }
            footer={<TabMenu useQueries tabs={tabs} />}
          />
          {tab && (
            <div className="pond-page-body">
              {tabs.find(({ name }) => name === tab)?.page}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default memo(Pond);
